import React from "react";
import { Helmet } from "react-helmet";
import styles from "./Article.module.css";
import SendMessage from "../../components/SendMessage";
import CardArticleNew from "../../components/CardArticleNew";
import CardArticle from "../../components/CardArticle";
import { Articles } from "../../data/article";

const Article = () => {
  return (
    <>
      <Helmet>
        <title>DEKKA - Always On Dashcam</title>
        <meta
          name="description"
          content="Dashcam DEKKA - Merekam perjalanan dan kejadian pentingmu 24 jam. Terdapat beberapa tipe yang bisa kamu pilih sesuai dengan kebutuhanmu."
        />
      </Helmet>
      <section className={styles.newArticleContainer}>
        <h2 className={styles.sectionTitle}>Artikel Terbaru</h2>
        <div className={styles.cardArticleNewContainer}>
          {Articles.slice(0, 1).map((article) => (
            <CardArticleNew
              title={article?.title}
              tag={article?.category}
              createdAt={article?.created_at}
              headline={article?.headline}
              slug={article?.slug}
              image={article?.banner}
            />
          ))}
        </div>
      </section>
      <section className={styles.articleContainer}>
        <h2 className={styles.sectionTitle}>Artikel Lainnya</h2>
        <div className={styles.cardArticleContainer}>
          {Articles.map((article, index) => (
            <CardArticle
              key={index}
              title={article.title}
              tag={article.category}
              createdAt={article.created_at}
              headline={article.headline}
              slug={article.slug}
              image={article?.banner}
            />
          ))}
        </div>
      </section>
      <SendMessage />
      <div className="slide-up-section">
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="slide-up-button"
        >
          <img src="/arrow-up.png" alt="Arrow Up Icon" />
        </button>
      </div>
    </>
  );
};

export default Article;
