export const listBranch = [
  {
    name: "Jabodetabek",
    link: "https://www.superspring.co.id/list_branch/eyJpdiI6InBKZkhFdnQxc0RCU01oMGlMaTRzVVE9PSIsInZhbHVlIjoiN1BSeUo2RGhISUpOZDlmR3gyTk1uZz09IiwibWFjIjoiMzAwNmMxZmM1MDVkOTFkYmUxZmMxMmEyMjIzNDhkMDY1NDYzNzQ2YTZjNzgzOTc1ODZkNjMwNTkyZGI0MmI0NiIsInRhZyI6IiJ9",
  },
  {
    name: "Jawa",
    link: "https://www.superspring.co.id/list_branch/eyJpdiI6ImIrbEhhRnBWRzNOQS9CZVY4Wm0wUnc9PSIsInZhbHVlIjoibEIxdWQ0MjFKVjlvOGhqa2pWOGprZz09IiwibWFjIjoiNjE1MGI5NWRhOGRhODhjZDA4YjIzNjE4N2ZmYmRjZmFiMmMzZjg3ZGE4NzQ5YTFmOWViNDIxNTIzYTJiYWYzMCIsInRhZyI6IiJ9",
  },
  {
    name: "Kalimantan",
    link: "https://www.superspring.co.id/list_branch/eyJpdiI6IlVqeEFnb3lXN1prNEcvTFc4QTBpQmc9PSIsInZhbHVlIjoiTUxRcTIyQ25wZ0dUVkVqOUgraXV3QT09IiwibWFjIjoiOWIyZjUxMmIyYTRhMDlhYjM0NTg5NjJlYTM0MDMzOGQ5ZGExMzdmNWYwYzc3MjhiMDJhNTIzNWEwYTYzMGM1MSIsInRhZyI6IiJ9",
  },
  {
    name: "NTB",
    link: "https://www.superspring.co.id/list_branch/eyJpdiI6InFQMHVkZmgrWndHR1VacVh2K1FObmc9PSIsInZhbHVlIjoibG1pa0lTOEFxeVlhT0ZtVVpTL0MwUT09IiwibWFjIjoiNWNlNTRkNWJiYjg1NWZmMzJjNDY2ZGFiMzg5M2MyZTQxZTg1ZDM5ZTk4MmZmYWJlZThiMzIyNGM0MWY2ZDY3NyIsInRhZyI6IiJ9",
  },
  {
    name: "Sulawesi",
    link: "https://www.superspring.co.id/list_branch/eyJpdiI6IkxsL0w0b0k3bnpvM2FXRWI3RXE1UFE9PSIsInZhbHVlIjoieVVzdFRqUE9kWGg0dko0YmxmMHpyUT09IiwibWFjIjoiYTI3ZGIzYWExOTZkMzZiNWM3NzJjMjY2ZTkwMmI2ZGY0MzE4ZTc1MDYwYTY5NTMyMzk0NDM5ZTVjMjYzMmY2NCIsInRhZyI6IiJ9",
  },
  {
    name: "Sumatera",
    link: "https://www.superspring.co.id/list_branch/eyJpdiI6IlVJdlA3a2NlUlA2MEp6cG95OEs5ZlE9PSIsInZhbHVlIjoid05CRnFuOGtNUjRpZlB2VzRFZUlCQT09IiwibWFjIjoiNjY3YzUyYmZiNmU1ZWJmYjI5MzdiOTk0ZTc2ODIxN2U1NGFkMGFhZDhiNzgwNDQ3NjYyNTM0ZTA4MjczOWZkMCIsInRhZyI6IiJ9",
  },
  {
    name: "Bali",
    link: "https://www.superspring.co.id/list_branch/eyJpdiI6Ik9YWDB3Q1NObWtlWXQ3dDBRZTJ0OXc9PSIsInZhbHVlIjoiZ2VZVks5aG56OTJzVnNIWFNPdURwQT09IiwibWFjIjoiZjBiYjBlNzdmOWU5YmVkOGYzMGNkYzA4ZjkyYTczYWExYTI0NzNmY2NkNGU3NzRiMWFhOWMwNjk1OTk2ZmVlMyIsInRhZyI6IiJ9",
  },
  {
    name: "Jayapura",
    link: "https://www.superspring.co.id/list_branch/eyJpdiI6Ik4xZzRuYzFXbmdWV2NRZUVPMGhQMWc9PSIsInZhbHVlIjoiQVZNT1lRNldLRm1oK3orNGlUQ242QT09IiwibWFjIjoiOWQwMDc4YzQ1YTI4ZDliZmVkY2MxYTZmYTg5YTg0MTQ5Yzc1Y2VmZDg4MGFlNTM5YjlmYWM1ZWY0NjA2NDQ4OSIsInRhZyI6IiJ9",
  },
];

export const listAgen = [
  {
    name: "Jabodetabek",
    link: "https://www.superspring.co.id/list_dealer/eyJpdiI6IlBCU2NsUmd1NjVHUHo4bHhGOUh4Vnc9PSIsInZhbHVlIjoiU0o3bHVpbFZMNnExQ1ZROHhvRVJMQT09IiwibWFjIjoiZmU0NGRmODY0N2ExOTY0ZmVmYWM4Mjk0ZTJlOTg2MTlkMGE1MDRhZDAwYTNkNGZkMjBjZjliYzgwYjRiNDU2ZiIsInRhZyI6IiJ9",
  },
  {
    name: "Sumatera",
    link: "https://www.superspring.co.id/list_dealer/eyJpdiI6ImxHVUw2a1QyOVZYQXl2eW5NOEJ1M3c9PSIsInZhbHVlIjoiV2Y2ZXhiYnV1ZVVQWGNUeHF5OHpidz09IiwibWFjIjoiMGY1MjNmOWNlNDBiNjg5MzBkMzFjODFlOTJmNTI3ZjUzNDlkMDdlYTkwN2I4YmFmZGQxMDUxYTczNGJiYzQ4NSIsInRhZyI6IiJ9",
  },
];

export const jabodetabekBranch = [
  {
    name: "Head Office",
    link: "https://goo.gl/maps/EtCkNeVXLsDPxP2D6",
    address:
      "SUPERSPRING Building, Jalan Cideng Timur No. 81 Petojo Selatan, Gambir Jakarta Pusat, 10160",
    phone: "(021) 2206 2222, 08111877333",
  },
  {
    name: "JAKARTA - SSC JAKPUS",
    link: "https://goo.gl/maps/rQrtURTSaYFV45Lb7",
    address:
      "MGK Kemayoran, Lantai 1 Blok C3 No. 6 Jalan Angkasa Kav B-6, Kemayoran Jakarta Pusat, 10610",
    phone: "0812-9171-6788",
  },
  {
    name: "JAKARTA - SSC DAAN MOGOT",
    link: "https://goo.gl/maps/zqXKa4LNQcRXNTd7A",
    address:
      "Jalan EE Raya No. 35, RT.11/RW.4, Cengkareng Barat, Kecamatan Cengkareng, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11730",
    phone: "(021) 5436 0360, 082211035555",
  },
  {
    name: "TANGERANG - SSC TANGERANG",
    link: "https://goo.gl/maps/sSLuo6MhVThTEzuj6",
    address:
      "Ruko Mendrisio 2 Blok B No. 3, Jalan Boulevard iL Lago,  Cihuni, Pagedangan  Banten, 15332",
    phone: "(021) 2222 5014, 08119006481",
  },
  {
    name: "BOGOR - SSC BOGOR",
    link: "https://goo.gl/maps/e1z6GnR22p8Kbusp8",
    address:
      "Jalan Gandaria II B9 No. 4  Tegal Gundil, Bogor Utara Bogor, 16152",
    phone: "(0251) 834-9192, 0895 7042 55668",
  },
  {
    name: "DEPOK - SSC DEPOK",
    link: "https://goo.gl/maps/TPQRSBeW1KAVrsqq5",
    address:
      "Jalan Pondok Petir Raya No. 168 Pondok Petir, Bojongsari  Depok, 16524",
    phone: "(021) 7477 3765, 081315392044",
  },
  {
    name: "BEKASI - SSC BEKASI",
    link: "https://maps.app.goo.gl/iJHV47L6yhNPo52w6",
    address:
      "Ruko Asia Tropis AT15/20 Harapan Indah, Bekasi Jawa Barat, 17214",
    phone: "(021) 8885 6195, 082210206000",
  },
  {
    name: "JAKARTA - SSC JAKARTA SELATAN",
    link: "https://goo.gl/maps/HTeHS6fx9zpKMMQt7",
    address:
      "Jalan Ahmad Yani No 1B, RT. 05 / RW 06,Kel. Jati Padang, Kec. Pasar Minggu.Kota Jakarta Selatan - 12540",
    phone: "0811-812-165",
  },
  {
    name: "CIBINONG - SSC CIBINONG",
    link: "https://goo.gl/maps/nYwXXEbhrhkdMj5Z9",
    address:
      "Jalan Raya Jakarta - Bogor Km. 43 No. 16, Pabuaran, Cibinong Kab. Bogor 16916",
    phone: "0813 9827 5758",
  },
];

export const jawaBranch = [
  {
    name: "BANDUNG - SSC BANDUNG",
    link: "https://goo.gl/maps/UHMERMYECCoS8saq8",
    address:
      "Jalan Jendral Sudirman No.174  Kebon Jeruk, Andir Bandung 40192",
    phone: "085100708233",
  },
  {
    name: "PURWAKARTA - SSC PURWAKARTA",
    link: "https://goo.gl/maps/h7Jz6qYyW9Vv6DT87",
    address:
      "Jalan Ibrahim Singadilaga No. 29 Nagri Kaler, Purwakarta Purwakarta, 41115",
    phone: "085319000770",
  },
  {
    name: "SURABAYA - SSC SURABAYA",
    link: "https://goo.gl/maps/fAawkAVGUDaSWpDf9",
    address:
      "Jalan Klampis Jaya No. 37A Klampis Ngasem, Sukolilo Surabaya, 60117",
    phone: "(031) 5917 4246 , 081294877333 , 0819877333 , 08155288333",
  },
  {
    name: "CIREBON - SSC CIREBON",
    link: "https://goo.gl/maps/EB5VbkcyLjdpex88A",
    address:
      "Komplek Bumi Linggahara 4 No. 3C, Jalan Brigadir Jenderal Darsono  Kertawinangun, Kedawu Cirebon, 45153",
    phone: "(0231) 2343 11, 089676909008",
  },
  {
    name: "SOLO - BLITZ VARIASI",
    link: "https://goo.gl/maps/t4M9Lvd9gx7AS6Q78",
    address:
      "Jalan DR. Rajiman No. 387  Panularan, Laweyan Surakarta, 57147",
    phone: "(0271) 7231 27, 088211221112",
  },
  {
    name: "SOLO - TIGA JAYA VARIASI",
    link: "https://goo.gl/maps/1gDwRrax7BvpGRBJ7",
    address:
      "Jalan Slamet Riyadi No. 346  Penumping, Laweyan Surakarta, 57141",
    phone: "(0271) 718988",
  },
  {
    name: "SEMARANG - DIAN TARA",
    link: "https://goo.gl/maps/VXSadpzZ6Z9wq9DKA",
    address:
      "Jalan Tentara Pelajar No. 12 Lamper Kidul, Semarang Selatan Semarang, 50256",
    phone: "082138926542",
  },
  {
    name: "SEMARANG - MITRA JAYA",
    link: "https://goo.gl/maps/3Ckk5JRXr8fWvvK69",
    address:
      "Jalan Kalimas Raya No. A15 Panggung Lor, Semarang Utara Semarang, 50177",
    phone: "08995976699",
  },
  {
    name: "PASURUAN - GLOBAL TECH",
    link: "https://goo.gl/maps/aEF9s9B8ceMRFK1P7",
    address:
      "Jalan Panglima Sudirman Ruko Grand Parimas Blok D No. 1  Purworejo, Kecamatan PurworejoPasuruan, 67116",
    phone: "(0343) 5615 660, 0817301161",
  },
  {
    name: "YOGYAKARTA - SSC JOGJA",
    link: "https://goo.gl/maps/Q4doQtfnnhtMnfjL7",
    address:
      "Jalan Kyai Mojo No. 64B  Bener, Tegalrejo Yogyakarta, 55244",
    phone: "(0274) 4284 194, 08112652688",
  },
  {
    name: "SURABAYA - JURAGANGPS",
    link: "https://goo.gl/maps/5tw831K7PopFL3V36",
    address:
      "Jalan Kutisari Selatan IVA/9 Kutisari, Tenggilis Mejoyo Surabaya, 60291",
    phone: "085172467878",
  },
  {
    name: "TEGAL - VARIASI TEGAL OUTLET",
    link: "https://goo.gl/maps/FW4zRhvgrdoZtgQR7",
    address:
      "Jalan Kolonel Sudiarto, Ruko Dwika No. A7-8 Panggung, Tegal Timur Tegal, 52122",
    phone: "(0283) 3428 38, 085713474563",
  },
  {
    name: "PEKALONGAN - OSINDO",
    link: "https://goo.gl/maps/mG4VhmPj81VEB1tU6",
    address:
      "Jalan Dr. Sutomo No. 115A Baros, Pekalongan Timur Pekalongan, 51129",
    phone: "085713430933",
  },
  {
    name: "PURWOKERTO - INDRA VARIASI",
    link: "https://goo.gl/maps/vLDDvmVCjJEBpPTz7",
    address:
      "Jalan Gerilya Timur No. 54 Karanganyar, Purwokerto Kidul Purwokerto, 53147",
    phone: "0818211900",
  },
  {
    name: "SUKABUMI - SSC SUKABUMI",
    link: "https://goo.gl/maps/TrMRskoKtG6awtLt9",
    address:
      "Perumahan Bumi Babakan Damai, Jalan Belida No.12, RT.32/RW10 Babakan, Cisaat Sukabumi, 43152",
    phone: "085871248899",
  },
  {
    name: "SERANG - SSC SERANG",
    link: "https://goo.gl/maps/4kJNWetNyKG9NxzVA",
    address:
      "Jalan Raya Serang Km. 14,5 No. 16  Pasir Gadung, Cikupa Tangerang, 15710",
    phone: "082129632932",
  },
  {
    name: "KUDUS - HARAPAN VARIASI",
    link: "https://goo.gl/maps/dAcEbesT791E3EHJ6",
    address: "Jalan Raya Pati Kudus Km. 2,5 Dersalam, Bae Kudus, 59313",
    phone: "087832177779",
  },
  {
    name: "TUBAN - WARAS JAYA",
    link: "https://goo.gl/maps/zM4g1veFtTdsFfRy8",
    address:
      "Jalan Basuki Rachmad No. 190 Ronggomulyo, Tuban Tuban, 62315",
    phone: "081252883337",
  },
];

export const kalimantanBranch = [
  {
    name: "BALIKPAPAN - SSC BALIKPAPAN",
    link: "https://goo.gl/maps/iGRmKJjuPe3H2MbA6",
    address:
      "Komplek Balikpapan Baru Ruko Sentra Eropa 2, Blok AB.6, No.32, Damai - Balikpapan Selatan Balikpapan, 76114",
    phone: "(0542) 8510 999, 0812 339 22888",
  },
  {
    name: "SAMARINDA - SSC SAMARINDA",
    link: "https://goo.gl/maps/4Fov3wDarYnHjwgz9",
    address:
      "Jalan Gunung Cermai No. 23 Jawa, Samarinda Ulu Samarinda, 75122",
    phone: "(0541) 7273 333, 081233922888",
  },
  {
    name: "PONTIANAK - SSC PONTIANAK",
    link: "https://goo.gl/maps/JvxsTXuyZqVCm2wj7",
    address:
      "Jalan H. Agus Salim No. 36 Benua Melayu Darat, Pontianak Selatan Pontianak, 78117",
    phone: "08115731088",
  },
  {
    name: "SAMPIT - GALAXY COM",
    link: "https://goo.gl/maps/cyj9Fhb5m1fnqCyA9",
    address:
      "Jalan H.M. Arsyad No. 67 Mentawa Baru Hilir, Mentawa Baru Ketapang Kotawaringin Timur, 74323",
    phone: "085232290000",
  },
  {
    name: "PALANGKARAYA - RUKAN",
    link: "https://goo.gl/maps/wHM5DZ6YRwgaRrPY9",
    address:
      "Jalan Cenderawasih No. 07 Palangka, Jekan Raya Palangka Raya, 70112",
    phone: "085249118288",
  },
  {
    name: "BANJARBARU - SSC BANJAR",
    link: "https://maps.app.goo.gl/s7nKvrZnQvH4MJvJ6",
    address:
      "Jl Ayani KM30 Samping Masjid Nurul Muhajrin, Banjarbaru, Kalimantan Selatan",
    phone: "0821-5403-0304",
  },
];

export const ntbBranch = [
  {
    name: "LOMBOK - MITRA PERDANA LOMBOK",
    link: "https://goo.gl/maps/m5xaHLGTvE4cFcCL8",
    address:
      "Jalan Panca Usaha No. 26E Cilinaya, Cakranegara Mataram, 83239",
    phone: "(0370) 6380 05, 087885585580",
  },
];

export const sulawesiBranch = [
  {
    name: "MAKASSAR - ASLIMI CARE",
    link: "https://goo.gl/maps/P3ZguigdsfseHc3F7",
    address:
      "Jalan Toddopuli Raya Timur (dalam area SPBU) No. 21 Borong, Manggala Makassar, 90233",
    phone: "081356058232",
  },
  {
    name: "PALU - MOBILSTATION",
    link: "https://goo.gl/maps/ya6LEcnvnMMmsPC68",
    address:
      "Jalan H. Hayun No. 60 Besusu Barat, Palu Timur Palu, 94111",
    phone: "(0451) 457960",
  },
  {
    name: "MANADO - ANTZ AUDIO WORKSHOP",
    link: "https://goo.gl/maps/ZR3L4eCoRqUbE5o87",
    address: "Jalan Arnold Mononutu No. 90 Pakowa, Wanea Manado, 95116",
    phone: "082187914660",
  },
  {
    name: "GORONTALO - SSC GORONTALO",
    link: "https://goo.gl/maps/HgUXwyeWgkYbzSwV9",
    address:
      "Jalan Nani Wartabone No.144 Heledulaa Selatan, Kota Timur Gorontalo 96134",
    phone: "081143206001",
  },
];

export const sumateraBranch = [
  {
    name: "LAMPUNG - PT. MASTEL MANDIRI",
    link: "https://goo.gl/maps/1uZvexpkpprPYhsr7",
    address:
      "Jalan Pangeran Antasari LK II No. 3 Kedamaian, Kecamatan Kedamaian Bandar Lampung, 35144",
    phone: "(0721) 7622 765, 08117203031",
  },
  {
    name: "PALEMBANG - SSC PALEMBANG",
    link: "https://goo.gl/maps/G4ND721UhHETzpH67",
    address:
      "Jalan Lebak Rejo No. A8 Sekip Bendung Sekip Jaya, Kemuning Palembang, 30126",
    phone: "085366478666",
  },
  {
    name: "MUARA BUNGO - RAPTOR CCTV",
    link: "https://goo.gl/maps/HaPbRFWkiC6bMkx96",
    address:
      "Jalan Jendral Sudirman No. 19 Pasar Atas, Muara Bungo Jambi, 37212",
    phone: "0811749995",
  },
  {
    name: "PEKANBARU - SSC PEKANBARU",
    link: "https://goo.gl/maps/xr5xK2nTg9uG3zVr8",
    address:
      "Jalan Riau No. 149C Padang Terubuk, Senapelan Riau, 28291",
    phone: "(0761) 8510 10, 0877 9077 9888",
  },
  {
    name: "BANGKA - SSC BANGKA",
    link: "https://goo.gl/maps/jxKyT7v5BpiKqUuE6",
    address:
      "Jalan Bahagia No. 79 Pasar Padi, Girimaya Pangkal Pinang, 33684",
    phone: "085279518888",
  },
  {
    name: "PADANG - CV PRATAMA SUKSES MANDIRI",
    link: "https://goo.gl/maps/hJxHNr2dyuUyUDCA8",
    address:
      "Jalan Ujung Belakang Olo No. 14E Olo, Padang Barat Padang, 25117",
    phone: "(0751) 8950 916, 081266600800",
  },
  {
    name: "KOTABUMI LAMPUNG - TOP MOTOR",
    link: "https://goo.gl/maps/iM3sZYDZTSBHP3hH9",
    address:
      "Jalan Lintas Sumatera No. 002C Candimas, Abung Selatan Lampung Utara, 34581",
    phone: "(0724) 3281 55, 085384447005",
  },
  {
    name: "MEDAN - SSC MEDAN",
    link: "https://goo.gl/maps/LNCyPRpRkZmbDfJ6A",
    address:
      "Jalan T. Amir Hamzah Blok B No. 6 Helvetia Timur, Medan Helvetia Medan, 20124",
    phone: "(061) 4257 9222, 0811551222",
  },
  {
    name: "DUMAI - SSC DUMAI RIAU",
    link: "https://goo.gl/maps/rRpi7TXkjNmfsPDm9",
    address:
      "Jalan M. Husni Thamrin Pangkalan Sesai, Dumai Barat Dumai, 28824",
    phone: "085365278898",
  },
  {
    name: "JAMBI - SSC JAMBI",
    link: "https://goo.gl/maps/Zm6S3GMqRK3m4oQn8",
    address:
      "Jalan Lintas Sumatra Sarolangun Kembang, Sarolangun Jambi, 37481",
    phone: "0811741728",
  },
  {
    name: "JAMBI - MENARA VARIASI",
    link: "https://maps.app.goo.gl/XMfPjNFwkcSHAd359",
    address:
      "Jl Hayam Wuruk No. 43-44 Kec Jelutung Kota Jambi Provinsi Jambi",
    phone: "08127405656",
  },
];

export const baliBranch = [
  {
    name: "BALI - ASTA MEDIA",
    link: "https://goo.gl/maps/jpi84wXPHUaxZriz9",
    address:
      "Jalan Teuku Umar Barat No.77, Dauh Puri Klod, Kec. Denpasar Barat, Kota Denpasar, Bali 80119",
    phone: "(0361) 481819, 0812 4624 6660",
  },
  {
    name: "BALI - BLITZ TABANAN",
    link: "https://maps.app.goo.gl/GHXCeVfqUQ1nDcEL6",
    address:
      "Jalan Dr. Ir. Soekarno Banjar Anyar, Kediri Tabanan, 82121",
    phone: "081916290633",
  },
];

export const jayapuraBranch = [
  {
    name: "JAYAPURA - BUANA VARIASI",
    link: "https://goo.gl/maps/dwnFwWwx7bAzquGA8",
    address:
      "Jalan Percetakan Negara No. 70 Gurabesi, Jayapura Utara Jayapura, 99221",
    phone: "082189358425",
  },
];

export const jabodetabekAgen = [
  {
    name: "HITECH",
    address:
      "MALL MANGGA DUA LT.5 Jalan Mangga Dua Raya No.1, Mangga Dua Sel., Kecamatan Sawah Besar Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10730",
    phone: "0895632593578",
    lat: null,
    lon: null,
  },
  {
    name: "GUDANG GPS COM",
    address:
      "Jalan Komp. Griya Bukit Jaya No.19  RT.10/RW.24, Tlajung Udik, Kec. Gn. Putri Kabupaten Bogor, Jawa Barat 16963",
    phone: "081285356924",
    lat: null,
    lon: null,
  },
];

export const sumateraAgen = [
  {
    name: "STAR PHONE",
    address:
      "Jalan T. Panglima Polem No.189  Peunayong, Kec. Kuta Alam Kota Banda Aceh, Aceh",
    phone: "085260222229",
    lat: null,
    lon: null,
  },
];
