import "./style.css";

import toast from "react-hot-toast";
import Form from "react-bootstrap/Form";
import { useEffect, useState } from "react";

const Warranty = () => {
  const [formClaim, setFormClaim] = useState({
    name: "",
    unit_type: 0,
    serial_num: "",
    date: "",
  });
  const [formActivation, setFormActivation] = useState({
    serial_num: "",
    name: "",
    phone_num: "",
    unit_type: 0,
    email: "",
  });
  const [unitList, setUnitList] = useState([
    {
      id: 2,
      nama_unit: "DK20",
    },
    {
      id: 1,
      nama_unit: "DK10",
    },
    {
      id: 3,
      nama_unit: "DK40",
    },
  ]);

  useEffect(() => {
    (async function () {
      try {
        const req = await fetch("https://api-apps.gps.id/dekka/tipe-unit");
        const res = await req.json();
        setUnitList(res.data);
      } catch (err) {
        if (process.env.NODE_ENV === "development") console.log(err);
      }
    })();
  }, []);

  const handleFormActivationChange = (e) => {
    const name = e.target.getAttribute("name");
    setFormActivation({
      ...formActivation,
      [name]: e.target.value,
    });
  };

  const handleFormClaimChange = (e) => {
    const name = e.target.getAttribute("name");
    setFormClaim({
      ...formClaim,
      [name]: e.target.value,
    });
  };

  const handleActivationSubmit = async () => {
    try {
      if (
        formActivation.email === "" ||
        formActivation.name === "" ||
        formActivation.phone_num === "" ||
        formActivation.serial_num === "" ||
        formActivation.unit_type === 0
      ) {
        toast.error("Tolong lengkapi semua data!");
        return;
      }

      const reqData = {
        serial_number: formActivation.serial_num,
        nama: formActivation.name,
        no_telepon: formActivation.phone_num,
        tipe_unit: Number(formActivation.unit_type),
        email: formActivation.email,
      };
      const req = await fetch("https://api-apps.gps.id/dekka/warranty", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqData),
      });
      if (req.status !== 201)
        return toast.error("Terjadi kesalahan, silahkan coba lagi");
      toast.success("Aktivasi garansi berhasil");
      setFormActivation({
        serial_num: "",
        name: "",
        phone_num: "",
        unit_type: 0,
        email: "",
      });
    } catch (err) {
      if (process.env.NODE_ENV === "development") console.log(err);
      toast.error("Terjadi kesalahan, silahkan coba lagi!");
    }
  };

  const handleClaimSubmit = async () => {
    try {
      if (
        formClaim.name === "" ||
        formClaim.serial_num === "" ||
        formClaim.unit_type === 0 ||
        formClaim.date === ""
      ) {
        toast.error("Tolong lengkapi semua data!");
        return;
      }

      const reqData = {
        serial_number: formClaim.serial_num,
        nama: formClaim.name,
        tipe_unit: Number(formClaim.unit_type),
        tanggal_pembelian: formClaim.date,
      };
      const req = await fetch("https://api-apps.gps.id/dekka/claim-garansi", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqData),
      });
      if (req.status !== 201)
        return toast.error("Terjadi kesalahan, silahkan coba lagi");
      toast.success("Klaim garansi berhasil");
      setFormClaim({
        name: "",
        unit_type: 0,
        serial_num: "",
        date: "",
      });
    } catch (err) {
      if (process.env.NODE_ENV === "development") console.log(err);
      toast.error("Terjadi kesalahan, silahkan coba lagi!");
    }
  };

  return (
    <div className="parent">
      <div className="header">
        {/* <h1 className="my-5">Aktivasi Garansi</h1> */}
      </div>

      <div className="body-content">
        <div className="row-form my-5">
          <h2>Aktivasi Garansi DEKKA</h2>
          <p>
            Lakukan aktivasi garansi unit DEKKA dengan mengisi form di bawah
            untuk menikmati garansi rusak ganti baru selama 2 tahun.
          </p>
          <div className="form-wrapper p-5">
            <div className="row">
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">Nama</span>
                <input
                  type="text"
                  name="name"
                  onChange={handleFormActivationChange}
                  value={formActivation.name}
                />
              </div>
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">No Handphone</span>
                <input
                  type="number"
                  name="phone_num"
                  onChange={handleFormActivationChange}
                  value={formActivation.phone_num}
                />
              </div>
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">Email</span>
                <input
                  type="email"
                  name="email"
                  onChange={handleFormActivationChange}
                  value={formActivation.email}
                />
              </div>
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">Tipe DEKKA</span>
                <Form.Select
                  aria-label="Default select example"
                  className="select-input"
                  name="unit_type"
                  onChange={handleFormActivationChange}
                  value={formActivation.unit_type}
                >
                  <option value="">Open this select menu</option>
                  {unitList.map((unit) => (
                    <option value={unit.id}>{unit.nama_unit}</option>
                  ))}
                </Form.Select>
              </div>
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">Serial Number</span>
                <input
                  type="text"
                  name="serial_num"
                  onChange={handleFormActivationChange}
                  value={formActivation.serial_num}
                />
                <div className="note-label d-flex align-items-center gap-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M9.99935 18.3333C14.6018 18.3333 18.3327 14.6025 18.3327 9.99996C18.3327 5.39746 14.6018 1.66663 9.99935 1.66663C5.39685 1.66663 1.66602 5.39746 1.66602 9.99996C1.66602 14.6025 5.39685 18.3333 9.99935 18.3333Z"
                      fill="#ECF6FB"
                    />
                    <path
                      d="M9.99935 11.4583C10.341 11.4583 10.6243 11.175 10.6243 10.8333V6.66663C10.6243 6.32496 10.341 6.04163 9.99935 6.04163C9.65768 6.04163 9.37435 6.32496 9.37435 6.66663V10.8333C9.37435 11.175 9.65768 11.4583 9.99935 11.4583ZM10.766 13.0158C10.7243 12.9158 10.666 12.8241 10.591 12.7408C10.5077 12.6658 10.416 12.6075 10.316 12.5658C10.1131 12.4824 9.88557 12.4824 9.68268 12.5658C9.58268 12.6075 9.49102 12.6658 9.40768 12.7408C9.33268 12.8241 9.27435 12.9158 9.23268 13.0158C9.19102 13.1158 9.16602 13.2241 9.16602 13.3325C9.16602 13.4408 9.19102 13.5491 9.23268 13.6491C9.27435 13.7575 9.33268 13.8408 9.40768 13.9241C9.49102 13.9991 9.58268 14.0575 9.68268 14.0991C9.78268 14.1408 9.89102 14.1658 9.99935 14.1658C10.1077 14.1658 10.216 14.1408 10.316 14.0991C10.416 14.0575 10.5077 13.9991 10.591 13.9241C10.666 13.8408 10.7243 13.7575 10.766 13.6491C10.8077 13.5491 10.8327 13.4408 10.8327 13.3325C10.8327 13.2241 10.8077 13.1158 10.766 13.0158Z"
                      fill="#ECF6FB"
                    />
                  </svg>
                  <span>Serial number dapat dilihat di kemasan</span>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <button
                  className="btn btn-submit-garansi w-100"
                  onClick={handleActivationSubmit}
                >
                  Aktivasi Garansi
                </button>
              </div>
            </div>
          </div>
        </div>
        <h2 className="heading">SYARAT DAN KETENTUAN GARANSI DEKKA</h2>
        <div className="row-one my-5">
          <h2>Ketentuan Garansi DEKKA</h2>
          <ul>
            <li>Garansi berlaku selama 2 tahun sejak tanggal pembelian</li>
            <li>
              Pelanggan dibebaskan dari biaya kerusakan yang bukan disebabkan
              oleh kesalahan pemakaian, namun akan dikenakan biaya pengiriman
              (bila ada).
            </li>
            <li>
              Klaim garansi dapat dilakukan dengan mengisi formulir online yang
              bisa diakses dengan memindai QR.
            </li>
            <li>
              Pengguna hanya dapat melakukan klaim garansi apabila status
              garansi produk telah aktif.
            </li>
            <li>
              Garansi akan otomatis aktif setelah pengguna mengisi form pada QR
              di atas.
            </li>
            <li>
              Jika mengalami kendala dalam klaim garansi, silakan langsung
              datang ke kantor cabang SUPERSPRING Center terdekat maupun hubungi
              Customer Care 24 jam kami di nomor (+6221) 2202 2222 atau whatsapp
              08 111 877 333.
            </li>
          </ul>
        </div>
        <div className="row-two my-5">
          <h2>Garansi Tidak Berlaku Apabila:</h2>
          <ul>
            <li>Stiker serial number terindikasi pernah dilakukan perubahan</li>
            <li>
              Unit terindikasi terkena cairan, kemasukan serangga, atau benda
              asing lainnya.
            </li>
            <li>Telah dilakukan penggantian komponen oleh pihak lain.</li>
            <li>Pemakaian yang tidak wajar. Misalnya, terbentur atau jatuh.</li>
          </ul>
        </div>
        <div className="row-three my-5">
          <h2>Garansi Tidak Meliputi:</h2>
          <ul>
            <li>
              Aksesoris tambahan seperti SD Card, kabel USB dan sebagainya (jika
              ada)
            </li>
            <li>Komponen yang terbakar karena kesalahan arus listrik.</li>
          </ul>
        </div>
        <div className="row-form my-5">
          <h2>Form Klaim Garansi DEKKA</h2>
          <div className="form-wrapper p-5">
            <div className="row">
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">Nama</span>
                <input
                  type="text"
                  name="name"
                  onChange={handleFormClaimChange}
                  value={formClaim.name}
                />
              </div>
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">Tipe DEKKA</span>
                <Form.Select
                  aria-label="Default select example"
                  className="select-input"
                  name="unit_type"
                  onChange={handleFormClaimChange}
                  value={formClaim.unit_type}
                >
                  <option value="">Open this select menu</option>
                  {unitList.map((unit) => (
                    <option value={unit.id}>{unit.nama_unit}</option>
                  ))}
                </Form.Select>
              </div>
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">Serial Number</span>
                <input
                  type="text"
                  name="serial_num"
                  onChange={handleFormClaimChange}
                  value={formClaim.serial_num}
                />
                <div className="note-label d-flex align-items-center gap-2">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M9.99935 18.3333C14.6018 18.3333 18.3327 14.6025 18.3327 9.99996C18.3327 5.39746 14.6018 1.66663 9.99935 1.66663C5.39685 1.66663 1.66602 5.39746 1.66602 9.99996C1.66602 14.6025 5.39685 18.3333 9.99935 18.3333Z"
                      fill="#ECF6FB"
                    />
                    <path
                      d="M9.99935 11.4583C10.341 11.4583 10.6243 11.175 10.6243 10.8333V6.66663C10.6243 6.32496 10.341 6.04163 9.99935 6.04163C9.65768 6.04163 9.37435 6.32496 9.37435 6.66663V10.8333C9.37435 11.175 9.65768 11.4583 9.99935 11.4583ZM10.766 13.0158C10.7243 12.9158 10.666 12.8241 10.591 12.7408C10.5077 12.6658 10.416 12.6075 10.316 12.5658C10.1131 12.4824 9.88557 12.4824 9.68268 12.5658C9.58268 12.6075 9.49102 12.6658 9.40768 12.7408C9.33268 12.8241 9.27435 12.9158 9.23268 13.0158C9.19102 13.1158 9.16602 13.2241 9.16602 13.3325C9.16602 13.4408 9.19102 13.5491 9.23268 13.6491C9.27435 13.7575 9.33268 13.8408 9.40768 13.9241C9.49102 13.9991 9.58268 14.0575 9.68268 14.0991C9.78268 14.1408 9.89102 14.1658 9.99935 14.1658C10.1077 14.1658 10.216 14.1408 10.316 14.0991C10.416 14.0575 10.5077 13.9991 10.591 13.9241C10.666 13.8408 10.7243 13.7575 10.766 13.6491C10.8077 13.5491 10.8327 13.4408 10.8327 13.3325C10.8327 13.2241 10.8077 13.1158 10.766 13.0158Z"
                      fill="#ECF6FB"
                    />
                  </svg>
                  <span>Serial number dapat dilihat di kemasan</span>
                </div>
              </div>
              <div className="col-12 d-flex flex-column gap-2 mb-4">
                <span className="label">Tanggal Pembelian</span>
                <input
                  type="date"
                  name="date"
                  onChange={handleFormClaimChange}
                  value={formClaim.date}
                />
              </div>
              <div className="col-12 col-lg-3">
                <button
                  className="btn btn-submit-garansi w-100"
                  onClick={handleClaimSubmit}
                >
                  Klaim Garansi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Warranty;
