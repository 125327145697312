import "./Navbar.css";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import navbarIcon from "../../assets/img/navbar-dekka.png";
import CrossBlue from "../../assets/icons/CrossBlue";

const NavigationBar = () => {
  const [showNavList, setShowNavList] = useState(false);
  const [showNavListMobile, setShowNavListMobile] = useState(false);

  const location = useLocation();

  const isActive = (pathname) => {
    return location.pathname === pathname ? "activeLink" : "";
  };
  return (
    <>
      <div className="nav-wraper">
        <div className="navbar-parent">
          <Link to="/">
            <img src={navbarIcon} alt="Dekka" width={191} />
          </Link>
          <div className="navbar-route">
            <Link to="/" className={`navbar-routeLink ${isActive("/")}`}>
              Home
            </Link>
            <div className="product-item-wraper">
              <button
                to="/"
                onClick={() => setShowNavList(!showNavList)}
                className={`navbar-routeLink${
                  location.pathname.includes("product") ? " activeLink" : ""
                }`}
              >
                Produk
              </button>
              {showNavList && (
                <div className="nav-list">
                  <Link
                    className="nav-list-item"
                    to="/product/dk40"
                    onClick={() => setShowNavList(false)}
                  >
                    <img
                      src="/product/DK40.png"
                      alt="DK 40"
                      width={53}
                      height={40}
                    />
                    <h4>Dashcam DK40</h4>
                  </Link>
                  <Link
                    className="nav-list-item"
                    to="/product/dk20"
                    onClick={() => setShowNavList(false)}
                  >
                    <img
                      src="/product/DK20.png"
                      alt="DK 20"
                      width={53}
                      height={40}
                    />
                    <h4>Dashcam DK20</h4>
                  </Link>
                  <Link
                    className="nav-list-item"
                    to="/product/dk10"
                    onClick={() => setShowNavList(false)}
                  >
                    <img
                      src="/product/DK10.png"
                      alt="DK 10"
                      width={53}
                      height={40}
                    />
                    <h4>Dashcam DK10</h4>
                  </Link>
                </div>
              )}
            </div>
            <button
              to="/"
              onClick={() => setShowNavListMobile(true)}
              className={`navbar-routeLink product-item-mobile${
                location.pathname.includes("product") ? " activeLink" : ""
              }`}
            >
              Produk
            </button>
            <Link
              to="/lokasi"
              className={`navbar-routeLink ${isActive("/lokasi")}`}
            >
              Lokasi
            </Link>
            <Link
              to="/artikel"
              className={`navbar-routeLink${
                location.pathname.includes("artikel") ? " activeLink" : ""
              }`}
            >
              Artikel
            </Link>
            <Link
              to="/warranty"
              className={`navbar-routeLink ${isActive("/warranty")}`}
            >
              Garansi
            </Link>
          </div>
        </div>
      </div>
      <div
        className="nav-list-mobile"
        style={{
          transform: `${
            showNavListMobile ? "translateX(0)" : "translateX(100%)"
          }`,
        }}
        onClick={() => setShowNavListMobile(!showNavListMobile)}
      >
        <div className="nav-list-mobile-wrapper">
          <div className="nav-list-mobile-header">
            <h3>Produk</h3>
            <button onClick={() => setShowNavListMobile(false)}>
              <CrossBlue />
            </button>
          </div>
          <div className="nav-menu">
            <Link
              className="nav-menu-item"
              to="/product/dk40"
              onClick={() => setShowNavListMobile(false)}
            >
              <img src="/product/DK40.png" alt="DK 40" width={53} height={40} />
              <h4>Dashcam DK40</h4>
            </Link>
            <Link
              className="nav-menu-item"
              to="/product/dk20"
              onClick={() => setShowNavListMobile(false)}
            >
              <img src="/product/DK20.png" alt="DK 20" width={53} height={40} />
              <h4>Dashcam DK20</h4>
            </Link>
            <Link
              className="nav-menu-item"
              to="/product/dk10"
              onClick={() => setShowNavListMobile(false)}
            >
              <img src="/product/DK10.png" alt="DK 10" width={53} height={40} />
              <h4>Dashcam DK10</h4>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationBar;
