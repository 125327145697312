import { Link } from "react-router-dom";
import styles from "./CardArticleNew.module.css";

const CardArticleNew = ({ title, tag, createdAt, headline, slug, image }) => {
  return (
    <article className={styles.article}>
      <div className={styles.articleImageContainer}>
        <img
          className={styles.articleImage}
          sizes="100vw"
          alt={title}
          src={image}
        />
      </div>
      <div className={styles.articleCotent}>
        <div className={styles.articleContentHeader}>
          <ul className={styles.articleTag}>
            {tag.length > 0 &&
              tag.map((val) => (
                <li key={val} className={styles.articleTagItem}>
                  {val}
                </li>
              ))}
          </ul>
          <h3 className={styles.articleTitle}>{title}</h3>
          <p className={styles.articleDate}>{createdAt}</p>
        </div>
        <p className={styles.articleDescription}>{headline}</p>
        <Link to={"/artikel/" + slug} className={styles.articleLink}>
          <button className={styles.articleButton}>Baca Selengkapnya</button>
        </Link>
      </div>
    </article>
  );
};

export default CardArticleNew;
