import "./style.css";
import { useState } from "react";
import toast from "react-hot-toast";
import Fade from "react-reveal/Fade";
import { useForm } from "react-hook-form";
import phone from "../../assets/img/home-page/phone.png";
import location from "../../assets/img/home-page/location.png";
import calendar from "../../assets/img/home-page/calendar.png";

const SendMessage = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleSendMessage = async (data) => {
    setIsLoading(true);
    const reqData = {
      nama: data.name,
      email: data.email,
      pesan: data.message,
      telp: data.phone
    };
    try {
      await fetch("https://register.gps.id/dekka/send-question", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "API-Key":
            "!E7ov0tNucevd&~=+Ez:fw5X2#Q;Bv#Ngz$9eZHe=[tBs^Zkc2~_PuPp~U>}CRZ",
        },
        body: JSON.stringify(reqData),
      });
      reset()
      toast.success("Pertanyaan berhasil dikirimkan!")
    } catch (err) {
      toast.error("Terjadi kesalahan silahkan coba lagi!")
      if (process.env.NODE_ENV === "development") console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <div className="cta">
      <h1>Butuh Informasi Lainnya?</h1>
      <p>
        Jangan ragu untuk terhubung dengan Customer Care 24 jam kami yang siap
        membantumu
      </p>
      <div className="cta-form">
        <Fade>
          <div className="form-left">
            <h1>Kirim Pertanyaan</h1>
            <form
              className="row"
              onSubmit={handleSubmit(handleSendMessage)}
              autoComplete="off"
            >
              <div className="col-12">
                <span>Nama</span>
                <input
                  type="text"
                  placeholder="Masukkan nama kamu"
                  autoComplete="off"
                  {...register("name", {
                    required: "Nama tidak boleh kosong!",
                  })}
                  aria-invalid={errors.name ? "true" : "false"}
                />
                {errors.name && (
                  <span role="alert" className="error-message">
                    {errors.name?.message}
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <span>Nomor Handphone</span>
                <input
                  type="text"
                  placeholder="Masukkan nomor HP kamu"
                  autoComplete="off"
                  {...register("phone", {
                    required: "Nomor HP tidak boleh kosong!",
                  })}
                  aria-invalid={errors.phone ? "true" : "false"}
                />
                {errors.phone && (
                  <span role="alert" className="error-message">
                    {errors.phone?.message}
                  </span>
                )}
              </div>
              <div className="col-12 col-lg-6">
                <span>Email</span>
                <input
                  type="email"
                  placeholder="Masukkan email kamu"
                  autoComplete="off"
                  {...register("email", {
                    required: "Email tidak boleh kosong!",
                    pattern: {
                      value:
                        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                      message: "Email harus valid!",
                    },
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                />
                {errors.email && (
                  <span role="alert" className="error-message">
                    {errors.email?.message}
                  </span>
                )}
              </div>
              <div className="col-12">
                <span>Pesan</span>
                <textarea
                  cols="30"
                  rows="10"
                  placeholder="Tulis pesan kamu"
                  autoComplete="off"
                  {...register("message", {
                    required: "Pesan tidak boleh kosong!",
                  })}
                  aria-invalid={errors.message ? "true" : "false"}
                ></textarea>
                {errors.message && (
                  <span role="alert" className="error-message">
                    {errors.message?.message}
                  </span>
                )}
              </div>
              <div className="col-12">
                <button
                  className="btn-kirim btn"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-loader-circle"
                    >
                      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                    </svg>
                  ) : (
                    "Kirim Pertanyaan"
                  )}
                </button>
              </div>
            </form>
          </div>
        </Fade>
        <div className="form-right">
          <Fade bottom>
            <h1>Informasi Kontak</h1>
          </Fade>
          <Fade bottom>
            <div className="form-right-info">
              <div className="info-item info-loc">
                <img src={location} alt="Location Icon" />
                <span>
                  Jl. Cideng Timur No.81, Petojo Sel., Kecamatan Gambir, Kota
                  Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10160
                </span>
              </div>
              <div className="info-item">
                <img src={phone} alt="Phone Icon" />
                <span>021 - 2206 2222 (Call Center 24 Jam)</span>
              </div>
              <div className="info-item">
                <img src={calendar} alt="Calendar Icon" />
                <span>
                  Opening Hours : <br />
                  Senin - Minggu (24 Jam)
                </span>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
