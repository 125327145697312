import React from "react";
import { Helmet } from "react-helmet";
import styles from "./ArticleDetail.module.css";
import SendMessage from "../../components/SendMessage";
import { useParams } from "react-router-dom";
import { Articles } from "../../data/article";

const ArticleDetail = () => {
  const params = useParams();

  const article =
    Articles.filter((article) => article.slug === params.slug).length > 0
      ? Articles.filter((article) => article.slug === params.slug)[0]
      : null;

  return (
    <>
      <Helmet>
        <title>DEKKA - Always On Dashcam</title>
        <meta
          name="description"
          content="Dashcam DEKKA - Merekam perjalanan dan kejadian pentingmu 24 jam. Terdapat beberapa tipe yang bisa kamu pilih sesuai dengan kebutuhanmu."
        />
      </Helmet>
      <section className={styles.container}>
        <div className={styles.heading}>
          <div className={styles.headingContent}>
            <ul className={styles.articleTag}>
              {article?.category.length > 0 &&
                article?.category.map((category) => (
                  <li key={category} className={styles.articleTagItem}>
                    {category}
                  </li>
                ))}
            </ul>
            <p className={styles.articleDate}>{article?.created_at}</p>
          </div>
          <h2 className={styles.articleTitle}>{article?.title}</h2>
          <img
            className={styles.articleImage}
            sizes="100vw"
            alt={article?.title}
            src={article?.banner}
          />
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: article?.description }}
        ></div>
      </section>
      <SendMessage />
      <div className="slide-up-section">
        <button
          onClick={() => window.scrollTo(0, 0)}
          className="slide-up-button"
        >
          <img src="/arrow-up.png" alt="Arrow Up Icon" />
        </button>
      </div>
    </>
  );
};

export default ArticleDetail;
