import Fade from "react-reveal/Fade";
import styles from "./DoubleParkingFeature.module.css";
import Parking from "../../assets/img/home-page/home-parking.svg";

const DoubleParkingFeature = () => {
  return (
    <Fade bottom>
      <div className={styles.container}>
        <div className={styles.header}>
          <img
            className={styles.headerImage}
            src={Parking}
            width={60}
            alt="Parking Icon"
          />
          <h3 className={styles.headerTitle}>Dual Parking Mode</h3>
        </div>
        <div className={styles.featureItemContainer}>
          <div className={styles.featureItem}>
            <video width="100%" autoPlay loop muted>
              <source
                src="https://img.gps.id/dekka/detail/parkingmode.mp4"
                type="video/mp4"
              />
            </video>
            <p className={styles.featureItemDescription}>
              Tetap merekam video saat kondisi terparkir ataupun mesin kendaraan
              mati.
            </p>
          </div>
          <div className={styles.featureItem}>
            <video width="100%" autoPlay loop muted>
              <source
                src="https://img.gps.id/dekka/detail/parkingmode2.mp4"
                type="video/mp4"
              />
            </video>
            <p className={styles.featureItemDescription}>
              Mendeteksi dan merekam benturan tanpa terhapus saat
              kendaraan terparkir.
            </p>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default DoubleParkingFeature;
